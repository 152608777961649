import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

interface PhoneInputMaskProps {
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

function PhoneInputMask({value, handleChange} : PhoneInputMaskProps) {

  return (
    <TextField
      name="phone"
      placeholder="Введите ваш номер телефона *"
      value={value}
      onChange={handleChange}
      sx={{
        border: '1px solid white',
        borderRadius: '12px',
        width: '100%',
      }}
      required={true}
    />
  );
}

export default PhoneInputMask;
